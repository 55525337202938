import G_G from "./libs/G_G.js";
import {G_Bus} from "./libs/G_Control.js";

class Front extends G_G{
	constructor(){
		super();
		const _ = this;
		G_Bus
			.on(_,'showHideAccord',_.showHideAccord.bind(_))
			// .on(_,'showSearchResults',_.showSearchResults.bind(_))
			// .on(_,'closeSearchResults',_.closeSearchResults.bind(_))
			.on(_,'clearSearchInput',_.clearSearchInput.bind(_))
			.on(_,'search',_.search.bind(_))
			.on(_,'showParamsChoose',_.showParamsChoose.bind(_))
			.on(_,'hideParamsChoose',_.hideParamsChoose.bind(_))
			.on(_,'showHideProductAccord',_.showHideProductAccord.bind(_))
			.on(_,'showModal',_.showModal.bind(_))
			.on(_,'closeModal',_.closeModal.bind(_))
			.on(_,'showPhotos',_.showPhotos.bind(_))
			.on(_,'closePhotos',_.closePhotos.bind(_))
			.on(_,'showPhoto',_.showPhoto.bind(_))
			.on(_,'changeColor',_.changeColor.bind(_))
			.on(_,'changeSize',_.changeSize.bind(_))
			.on(_,'showFilter',_.showFilter.bind(_))
			.on(_,'showFilterAccord',_.showFilterAccord.bind(_))
			.on(_,'clearAllFilters',_.clearAllFilters.bind(_))
			.on(_,'searchFilterItems',_.searchFilterItems.bind(_))
			.on(_,'clearSearchFilterItems',_.clearSearchFilterItems.bind(_))
			.on(_,'multiSliderNext',_.multiSliderNext.bind(_))
			.on(_,'multiSliderPrev',_.multiSliderPrev.bind(_))
			.on(_,'infiniteSwipePrev',_.infiniteSwipePrev.bind(_))
			.on(_,'infiniteSwipeNext',_.infiniteSwipeNext.bind(_))
			.on(_,'lensButton',_.lensButton.bind(_))
			.on(_,'lensButtonChoose',_.lensButtonChoose.bind(_))
			.on(_,'select',_.select.bind(_))
			.on(_,'selectChoose',_.selectChoose.bind(_))
			.on(_,'showBlock',_.showBlock.bind(_))
			.on(_,'chooseColor',_.chooseColor.bind(_))
			.on(_,'showTooltip',_.showTooltip.bind(_))
			.on(_,'hideTooltip',_.hideTooltip.bind(_))
			.on(_,'chooseDensity',_.chooseDensity.bind(_))
			.on(_,'twoPD',_.twoPD.bind(_))
			.on(_,"showMoreSearch",_.showMoreSearch.bind(_))
			.on(_,"showLessSearch",_.showLessSearch.bind(_))
			.on(_,"showMore",_.showMore.bind(_))
			.on(_,"showLess",_.showLess.bind(_))
			.on(_,"test",_.test.bind(_))
			.on(_,"showSlidePhoto",_.showSlidePhoto.bind(_));
		G_Bus.on(_,[
			'signIn'
		])
		_.auth0Client = null;
	}
	define(){
		const _ = this;
		_.componentName = 'front';
		_.head = _.f('.head');
		_.modals = {};
		_.prevScrolled = 0;

		_.init()
	}

	test(clickData) {
		const _ = this;
	}
	scrollCheck(){
		const _ = this
		_.filterTopOffset = document.querySelector('.products')?.offsetTop;
		let winY = window.pageYOffset;
		_.productRightScrollCheck(winY);
		_.filterTopScrolled(winY);

		window.addEventListener('scroll',()=>{
			winY = window.pageYOffset;
			_.productRightScrollCheck(winY);
			_.filterTopScrolled(winY);
		})
	}
	productRightScrollCheck(winY){
		const _ = this;
		let block = _.f('.product-block-right');
		if (!block) return;
		if (winY > 0) {
			block.classList.add('scrolled')
		} else {
			block.classList.remove('scrolled')
		}
	}
	filterTopScrolled(winY){
		const _ = this;
		let filterTop = document.querySelector('.filter-top');
		if (!filterTop) return;

		let
			filterParent = filterTop.parentElement,
			headHeight = document.querySelector('.head-content')?.clientHeight,
			fullHeadHeight = headHeight ?? 0,
			realScrolled = winY + 12;

		if (realScrolled > filterParent.clientHeight - window.innerHeight + _.filterTopOffset + 200) {
			if (_.filterTopTimeOut) {
				clearTimeout(_.filterTopTimeOut)
				_.filterTopTimeOut = null;
			}
			filterTop.style = `top:${filterParent.clientHeight - window.innerHeight + 200 - 12}px;position:absolute;left:0;width:100%;`
		} else if (realScrolled > _.filterTopOffset) {
			filterTop.classList.add('scrolled');
			filterTop.style = '';
			if (!_.filterTopTimeOut){
				_.filterTopTimeOut = setTimeout(()=>{
					filterTop.style = `transition:.35s ease;`;
				},350)
			}
		} else if (realScrolled + fullHeadHeight < _.filterTopOffset) {
			filterTop.classList.remove('scrolled');
			filterTop.removeAttribute('style');
		}
	}

	showHideAccord({item}){
		const _ = this;
		if (window.innerWidth >= 1024) return;
		if (!item.classList.contains('active')) {
			let cont = item.closest('UL');
			if (cont) {
				let activeBtn = cont.querySelector(`.${item.className.split(' ')[0]}.active`);
				if (activeBtn) {
					_.closeAccordItem(activeBtn)
				}
			}

			if (item.className.indexOf('head') >= 0) _.showHeadAccordItem(item);
			else _.showFootAccordItem(item);
		} else {
			_.closeAccordItem(item);
		}
	}
	calcInnerHeight(cont){
		let sumHeight = 0;
		for(let item of cont.children) {
			sumHeight += item.offsetHeight;
		}
		return sumHeight;
	}
	showHeadAccordItem(btn){
		const _ = this;
		let list = btn.nextElementSibling;
		if (!list.hasAttribute('data-height')) {
			let sumHeight = _.calcInnerHeight(list);
			sumHeight += 19 * (list.children.length - 1) + 21;
			list.setAttribute('data-height',`height:${sumHeight}px;`);
		}

		list.style = list.getAttribute('data-height');
		setTimeout(()=>{
			btn.classList.add('active');
			list.classList.add('active');
		})
	}
	showProductAccordItem(btn){
		const _ = this;
		let list = btn.nextElementSibling;
		if (!list.hasAttribute('data-height')) {
			let sumHeight = _.calcInnerHeight(list);
			sumHeight += 50;
			list.setAttribute('data-height',`height:${sumHeight}px;`);
		}

		list.style = list.getAttribute('data-height');
		setTimeout(()=>{
			btn.classList.add('active');
			list.classList.add('active');
		})
	}
	showFootAccordItem(btn){
		const _ = this;
		let list = btn.nextElementSibling;
		if (!list.hasAttribute('data-height')) {
			let sumHeight = _.calcInnerHeight(list);
			sumHeight += 30 * list.children.length + 15;
			list.setAttribute('data-height',`height:${sumHeight}px;`);
		}

		list.style = list.getAttribute('data-height');
		setTimeout(()=>{
			btn.classList.add('active');
			list.classList.add('active');
			list.parentElement.classList.add('active')
		})
	}
	showHideProductAccord({item}){
		const _ = this;
		if (!item.classList.contains('active')) _.showProductAccordItem(item)
		else _.closeAccordItem(item)
	}
	closeAccordItem(btn){
		let list = btn.nextElementSibling;
		list.style = '';
		btn.classList.remove('active');
		setTimeout(()=>{
			list.classList.remove('active');
			list.parentElement.classList.remove('active')
		})
	}
	clearAccordsHeight(){
		const _ = this;
		setTimeout(()=>{
			document.querySelectorAll(`[data-height]`).forEach(item=>{
				item.removeAttribute('data-height');
				_.closeAccordItem(item.previousElementSibling)
			})
		})
	}

	// showSearchResults({item}){
	// 	const _ = this;
	// 	let result = _.head.querySelector('.head-results');
	// 	let input = _.head.querySelector('#searchInput');
	// 	_.f('.head-results-input').value = input.value;
	// 	result.classList.add('active');
	// 	document.body.classList.add('showed');
	// }
	// closeSearchResults({item}){
	// 	const _ = this;
	// 	let result = _.head.querySelector('.head-results');
	// 	result.classList.remove('active');
	// 	document.body.classList.remove('showed');
	// }
	search({item}){
		const _ = this;
		let form = item.closest('FORM');
		let results = {
			requests:[
				'black frames','black lenses','black temples','black inner temples'
			],
			data:[
				{
					img: 'img/cartier-product.png',
					title: 'Cartier',
					'vendor code':'CT0092O',
					'colors':2,
					'actual price':898,
					'price': 1145,
					'discount': 24
				},{
					img: 'img/cartier-product-black.png',
					title: 'Cartier',
					'vendor code':'CT0092O',
					'colors':2,
					'actual price':898,
					'price': 1145,
					'discount': 24
				},{
					img: 'img/cartier-product.png',
					title: 'Cartier',
					'vendor code':'CT0092O',
					'colors':2,
					'actual price':898,
					'price': 1145,
					'discount': 24
				},{
					img: 'img/cartier-product-black.png',
					title: 'Cartier',
					'vendor code':'CT0092O',
					'colors':2,
					'actual price':898,
					'price': 1145,
					'discount': 24
				}
			]
		};

	}
	clearSearchInput({item}){
		let
			form = item.closest('FORM'),
			input = form.querySelector('INPUT');
		input.value = '';
		input.focus();
	}

	showParamsChoose({item}){
		let
			cont = item.closest('.product-params-block'),
			params = cont.closest('.product-params'),
			rightBlock = cont.closest('.product-block-right'),
			hiddenBlock = cont.querySelector('.product-params-hidden');

		if (window.innerWidth < 1024) document.body.classList.add('showed');

		hiddenBlock.classList.add('active');
		rightBlock.classList.add('active');

		if (window.innerWidth < 1024) return;

		if (params.clientHeight < hiddenBlock.firstElementChild.clientHeight) params.style = `height:${hiddenBlock.firstElementChild.clientHeight}px`;
	}
	hideParamsChoose({item}){
		let
			hiddenBlock = item.closest('.product-params-hidden'),
			params = hiddenBlock.closest('.product-params'),
			rightBlock = hiddenBlock.closest('.product-block-right');

		document.body.classList.remove('showed');
		hiddenBlock.classList.remove('active');
		setTimeout(()=>{
			params.style = '';
			rightBlock.classList.remove('active');
		},350)
	}

	showModal({item}){
		const _ = this;
		let
			targetSelector = item.getAttribute('data-target'),
			target = document.querySelector(`${targetSelector}`),
			innerTpl = `<div class="modal-inner"><button class="modal-close" data-click="front:closeModal"><svg><use xlink:href="#product-params-close"></use></svg></button></div>`,
			inner = _.markupElement(innerTpl);
		_.modals[targetSelector.substr(1)] = target.parentElement;

		let modal = document.querySelector('.modal');
		modal.classList.add('active');

		modal.prepend(inner);
		inner.append(target);
		setTimeout(()=>{
			inner.classList.add('active');
		})

		target.setAttribute('data-selector',targetSelector);
		document.body.classList.add('showed');
	}
	closeModal({item}){
		const _ = this;
		let
			inner = item.closest('.modal-inner'),
			modal = item.closest('.modal');
		if (item.tagName != 'BUTTON') inner = modal.querySelector('.modal-inner');

		let
			target = inner.lastElementChild,
			parent = _.modals[target.getAttribute('data-selector').substr(1)];

		inner.classList.remove('active');
		setTimeout(()=>{
			parent.append(target);
			inner.remove();
			modal.classList.remove('active');
		},350)
		document.body.classList.remove('showed');
	}

	rebuildProductPage(){
		const _ = this;
		let targetBlock = _.f('.product-accordion');
		if (!targetBlock) return;
		if (window.innerWidth >= 1024) {
			_.f('.product-block-left').append(targetBlock);
		} else {
			_.f('.product-results').parentElement.insertBefore(targetBlock,_.f('.product-results'));
		}
	}

	slidersInit(){
		const _ = this;
		let photoSliders = document.querySelectorAll('.product-photo-slider');
		photoSliders.forEach((slider)=>{
			_.photoSliderInit(slider)
		})

		let multiSliders = document.querySelectorAll('.multiSlider');
		multiSliders.forEach((slider)=>{
			let isInfinite = slider.hasAttribute('isInfinite');
			if (isInfinite) _.infiniteSliderInit(slider);
			else _.multiSliderInit(slider);
		})

		_.delay = false;
	}
	photoSliderInit(slider){
		const _ = this;

		let
			touchstartX = 0,
			touchendX = 0;

		if (window.innerWidth >= 1024) {
			return;
		}

		slider.addEventListener('touchstart', e => {
			console.log('touchstart')
			touchstartX = e.changedTouches[0].screenX
		})

		slider.addEventListener('touchend', e => {
			touchendX = e.changedTouches[0].screenX;

			if (touchendX < touchstartX) {
				_.photoSwipe(slider,false,touchstartX - touchendX);
			} else if (touchendX > touchstartX) {
				_.photoSwipe(slider,true,touchendX - touchstartX);
			}
		})
	}
	photoSwipe(slider,toRight = false,difference){
		const _ = this;
		if (window.innerWidth >= 1024 || difference < 50) return;

		let
			list = slider.querySelector('.product-photo-list'),
			btnsCont = slider.querySelector('.product-photo-control');

		if (toRight) {
			list.prepend(list.lastElementChild);
			btnsCont.append(btnsCont.firstElementChild)
		} else {
			list.append(list.firstElementChild);
			btnsCont.prepend(btnsCont.lastElementChild)
		}
	}

	multiSlidersReInit(){
		const _ = this;
		document.querySelectorAll('.multiSlider').forEach(slider=>{
			let slides = slider.querySelector('.multiSlides');
			let indicator = slider.querySelector('.home-slider-indicator');
			if (indicator) indicator.style = '';
			if (_.needToInit(slides)) {
				slider.classList.add('active');
			} else {
				slider.classList.remove('active');
			}
		})
	}
	multiSliderInit(slider){
		const _ = this;
		let slides = slider.querySelector('.multiSlides');

		slides.removeAttribute('data-offsetCount');
		slides.removeAttribute('data-offset');

        if (slides.firstElementChild) {
            slides.firstElementChild.style = '';
        }

		slider.querySelector('.prev')?.setAttribute('data-click',`${_.componentName}:multiSliderPrev`)
		slider.querySelector('.next')?.setAttribute('data-click',`${_.componentName}:multiSliderNext`)

		slides.addEventListener('scroll',(e)=>{
			_.swipeSliderButtonsCheck(slider);
			_.moveSliderIndicator(slider)
		})

		/*if (_.needToInit(slides)) {
			slider.classList.add('active');
		} else {
			slider.classList.remove('active');
		}*/
	}
	swipeSliderButtonsCheck(slider){
		const _ = this;
		let
			next = slider.querySelector('.next'),
			prev = slider.querySelector('.prev'),
			slides = slider.querySelector('.multiSlides'),
			padding = _.getSlidesPadding(),
			offset = slides.scrollLeft,
			fullWidth = _.getSlidesWidth(slides);

		let isNoNext = offset + slides.clientWidth - 2 * padding >= fullWidth;
		if (isNoNext) {
			if (next) next.disabled = true;
			slides.setAttribute('data-nonext','');
		} else {
			if (next) next.disabled = false;
			slides.removeAttribute('data-nonext');
		}
		if (offset <= 0) {
			if (prev) prev.disabled = true;
			slides.setAttribute('data-noprev','');
		} else {
			if (prev) prev.disabled = false;
			slides.removeAttribute('data-noprev');
		}
	}
	needToInit(slider){
		let width = 0;
		for (let i = 0; i < slider.children.length; i++) {
			width += slider.children[i].clientWidth;
		}
		return slider.clientWidth < width - 40;
	}
	getSlidesGap(){
		let
			innerWidth = window.innerWidth,
			gap = innerWidth < 1024 ? 12 : 14;
		return gap;
	}
	getSlidesPadding(){
		let
			innerWidth = window.innerWidth,
			padding = 12;

		if (innerWidth >= 740 && innerWidth < 1024) padding = 18;
		else if (innerWidth >= 1000 && innerWidth < 1800) padding = 40;
		else if (innerWidth >= 1800) padding = 80;

		return padding;
	}
	getSlideWidth(slides){
		const _ = this;
		let
			gap = _.getSlidesGap(),
			slideWidth = slides.firstElementChild.clientWidth + gap;

		return slideWidth;
	}
	getSliderStep(slides){
		const _ = this;
		let
			innerWidth = window.innerWidth,
			slideStep = _.getSlideWidth(slides),
			padding = _.getSlidesPadding();

		let step = Math.floor((slides.clientWidth - (2 * padding)) / slideStep) * slideStep;
		return step;
	}
	getSlidesWidth(slides){
		const _ = this;
		let
			slideWidth = _.getSlideWidth(slides),
			gap = _.getSlidesGap(),
			fullWidth = slides.children.length * (slideWidth) - gap;
		return fullWidth;
	}
	multiSliderNext({item}){
		const _ = this;
		let
			slider = item.closest('.multiSlider'),
			slides = slider.querySelector('.multiSlides'),
			next = slider.querySelector('.next'),
			prev = slider.querySelector('.prev');

		if (_.delay || slides.hasAttribute('data-nonext')) return;

		let
			offset = slides.scrollLeft,
			step = _.getSliderStep(slides),
			curOffset = Math.floor(offset / step) * step + step,
			padding = _.getSlidesPadding(),
			fullWidth = _.getSlidesWidth(slides);

		let isNoNext = curOffset + slides.clientWidth - 2 * padding >= fullWidth;
		if (isNoNext) {
			curOffset = fullWidth - slides.clientWidth + 2 * padding;
			if (next) next.disabled = true;
			slides.setAttribute('data-nonext','');
		}

		slides.scrollTo(curOffset,0);

		if (prev) prev.disabled = false;
		slides.removeAttribute('data-noprev');

		_.slideDelay();
		setTimeout(()=>{
			_.moveSliderIndicator(slider);
		},500)
	}
	multiSliderPrev({item}){
		const _ = this;
		let
			slider = item.closest('.multiSlider'),
			slides = slider.querySelector('.multiSlides'),
			next = slider.querySelector('.next'),
			prev = slider.querySelector('.prev');

		if (_.delay || slides.hasAttribute('data-noprev')) return;

		let
			offset = slides.scrollLeft,
			step = _.getSliderStep(slides),
			curOffset;
		if (!slides.hasAttribute('data-nonext')) curOffset = Math.floor(offset / step) * step - step;
		else curOffset = Math.floor(offset / step) * step;

		if (curOffset < 0) curOffset = 0;
		slides.scrollTo(curOffset,0);

		if (curOffset <= 0) {
			if (prev) prev.disabled = true;
			slides.setAttribute('data-noprev','');
		}
		if (next) next.disabled = false;
		slides.removeAttribute('data-nonext');

		_.slideDelay();
		setTimeout(()=>{
			_.moveSliderIndicator(slider);
		},500)
	}
	moveSliderIndicator(slider){
		const _ = this;
		let indicator = slider.querySelector('.home-slider-indicator');
		if (!indicator) return;

		let
			slides = slider.querySelector('.multiSlides'),
			offset = slides.scrollLeft,
			padding = _.getSlidesPadding(),
			fullWidth = _.getSlidesWidth(slides),
			fullOffset = fullWidth - (slides.clientWidth - 2 * padding),
			moveSpace = slides.clientWidth - 2 * padding - indicator.clientWidth,
			left = offset / fullOffset;

		left = moveSpace * left + padding;
		indicator.style = `left:${left}px;`
	}

	infiniteSliderInit(slider){
		const _ = this;
		let slides = slider.querySelector('.multiSlides');
		slides.prepend(slides.lastElementChild);

		let
			touchstartX = 0,
			touchendX = 0;

		slider.addEventListener('touchstart', e => {
			touchstartX = e.changedTouches[0].screenX;
		})

		slider.addEventListener('touchend', e => {
			touchendX = e.changedTouches[0].screenX;
			if (touchendX < touchstartX && touchstartX - touchendX > 50) {
				_.infiniteSwipeNext({item:slides})
			} else if (touchendX > touchstartX && touchendX - touchstartX > 50) {
				_.infiniteSwipePrev({item:slides})
			}
		});

		slider.querySelector('.next')?.setAttribute('data-click',`${_.componentName}:infiniteSwipeNext`)
		slider.querySelector('.prev')?.setAttribute('data-click',`${_.componentName}:infiniteSwipePrev`)
	}
	infiniteSwipePrev({item}){
		const _ = this;
		if (_.delay) return;
		let
			slider = item.closest('.multiSlider'),
			slides = slider.querySelector('.multiSlides');
		slides.prepend(slides.lastElementChild);
		_.slideDelay();
	}
	infiniteSwipeNext({item}){
		const _ = this;
		if (_.delay) return;
		let
			slider = item.closest('.multiSlider'),
			slides = slider.querySelector('.multiSlides');
		slides.append(slides.firstElementChild);
		_.slideDelay();
	}
	slideDelay(){
		const _ = this;
		_.delay = true;
		setTimeout(()=>{
			_.delay = false;
		},350)
	}

	showPhotos({item,event}){
		const _ = this;
		_.productPhotoFullSizeToSlide = false;
		let target = event.target.closest('.product-photo-item');
		if (!target) return;

		let
			number = target.getAttribute('data-number'),
			innerTpl = `
				<div class="modal-inner full">
					<button class="modal-close" data-click="front:closePhotos">
						<svg>
							<use xlink:href="#product-params-close"></use>
						</svg>
					</button>
				</div>
			`,
			inner = _.markupElement(innerTpl),
			contTpl = '<div class="photos"></div>',
			cont = _.markupElement(contTpl),
			photosCont = item.cloneNode(true),
			photosControl = photosCont.cloneNode(true);

		photosCont.removeAttribute('data-click');
		photosControl.setAttribute('data-click','front:showPhoto');

		photosCont.className = 'photos-list';
		photosControl.className = 'photos-control';

		let
			len = photosControl.children.length,
			targetLen = 0,
			innerW = window.innerWidth;
		if (innerW < 768 && len > 4) {
			targetLen = 6;
		} else if (innerW >= 768 && innerW < 1024 && len > 6) {
			targetLen = 7;
		} else if (innerW >= 1024 && len > 9) {
			targetLen = 10;
		}

		if (targetLen) {
			_.productPhotoFullSizeToSlide = true;
			photosControl.classList.add('active');
			while(photosControl.children.length < targetLen) {
				for (let i = 0; i < len; i++) {
					let item = photosControl.children[i];
					let clone = item.cloneNode(true);
					photosControl.append(clone)
				}
			}
		}


		let modal = document.querySelector('.modal');
		modal.classList.add('active');

		modal.prepend(inner);
		inner.append(cont);
		cont.append(photosCont,photosControl);

		photosCont.querySelector(`[data-number="${number}"]`).classList.add('active');
		photosControl.querySelector(`[data-number="${number}"]`).classList.add('active');

		if (_.productPhotoFullSizeToSlide) {
			while(!photosControl.children[1].classList.contains('active')) {
				photosControl.append(photosControl.firstElementChild);
			}
		}

		document.body.classList.add('showed');
		setTimeout(()=>{
			inner.classList.add('active');
		})
	}
	closePhotos(clickData){
		const _ = this;
		let
			item = clickData ? clickData.item : document.querySelector('.modal-close');
		if (!item) return;

		let
			inner = item.closest('.modal-inner'),
			modal = item.closest('.modal');
		if (item.tagName != 'BUTTON') inner = modal.querySelector('.modal-inner');

		inner.classList.remove('active');
		setTimeout(()=>{
			inner.remove();
			modal.classList.remove('active');
		},350)
		document.body.classList.remove('showed');
	}
	showPhoto({item,event}){
		const _ = this;
		let
			target = event.target.closest('.product-photo-item');
		if (!target) return;
		let
			number = target.getAttribute('data-number'),
			cont = item.closest('.photos'),
			photosCont = cont.querySelector('.photos-list'),
			photosControl = cont.querySelector('.photos-control');

		photosCont.querySelector('.active').classList.remove('active');
		photosControl.querySelector('.active').classList.remove('active');

		target.classList.add('active')
		photosCont.querySelector(`[data-number="${number}"]`).classList.add('active');

		if (_.productPhotoFullSizeToSlide) {
			let int = setInterval(()=>{
				photosControl.append(photosControl.firstElementChild);
				if (photosControl.children[1].classList.contains('active')) {
					clearInterval(int);
				}
			},250);
		}
	}

	changeColor({item}){
		const _ = this;
		let
			id = item.getAttribute('data-id'),
			product = _.getProductInfo(id),
			cont = item.closest('.product-params'),
			titleBlock = cont.querySelector('.product-title-block'),
			priceBlock = cont.querySelector('.product-price'),
			colorsBlock = item.closest('.colors'),
			sizesBlock = cont.querySelector('.sizes'),
			photosSlider = _.f('.product-photo-slider');

		_.rebuildTitleBlock(titleBlock,product);
		_.rebuildPriceBlock(priceBlock,product);
		_.rebuildColorsBlock(colorsBlock,product);
		_.rebuildSizesBlock(sizesBlock,product);
		_.changePhotos(photosSlider,product);
		_.changeProductDetails(product)
	}
	rebuildTitleBlock(cont,data){
		cont.querySelector('.product-vendorcode').textContent = data.vendor;
	}
	rebuildPriceBlock(cont,data){
		const _ = this;
		let tpl = `
	    <div class="product-price-current red">$${data.actual_price}</div>
      <div class="product-price-old">$${data.price}</div>
      <div class="product-price-discount">${data.discount} off</div>
  	`;
		_.clear(cont);
		cont.append(_.markup(tpl))
	}
	rebuildColorsBlock(cont,data){
		const _ = this;
		let id = data.id;
		let activeColors = cont.querySelectorAll('.colors-item');
		activeColors.forEach((item)=>{
			if (item.getAttribute('data-id') == id) item.classList.add('active')
			else item.classList.remove('active');
		});

		let frameInfoBlock = cont.querySelector('.colors-info-frame');
		frameInfoBlock.querySelector('span').textContent = data.frame;

		let lensInfoBlock = cont.querySelector('.colors-info-lens');

		if (lensInfoBlock) {
			if(data.lens) lensInfoBlock.querySelector('span').textContent = data.lens;
			else lensInfoBlock.remove();
		} else {
			if(data.lens) {
				let tpl = `
					<div class="colors-info-lens"><strong>Lens:</strong><span>${data.lens}</span></div>
				`;
				frameInfoBlock.after(frameInfoBlock,_.markup(tpl))
			}
		}
	}
	rebuildSizesBlock(cont,data){
		const _ = this;
		let
			count = 0,
			btnsTpl = '',
			btnsInnerTpl = '';

		_.sizes = data.sizes;
		for (let item in data.sizes){
			let tpl = `
				<button class="sizes-picker-item${data.sizes.length == 1 ? ' active' : ''}" data-click="front:changeSize">
					<strong class="sizes-picker-title">${item}</strong>
					<span class="sizes-picker-wide">${data.sizes[item].wide}</span>
					<span class="sizes-picker-between">${data.sizes[item].between}</span>
					<span class="sizes-picker-length">${data.sizes[item].length}</span>
					<span class="sizes-picker-height">${data.sizes[item].height}</span>
				</button>
			`;
			if (count < 3) btnsTpl += tpl;
			btnsInnerTpl += tpl;
			count += 1;
		}

		let tpl = `
  	  <h5 class="product-params-title sizes-title">Sizes (${count})<img src="img/sizes.svg" alt=""></h5>
      <div class="sizes-picker">
		`;

		if (count <= 3) {
			tpl += btnsTpl;
		} else {
			tpl += `
	      <button class="sizes-picker-item sizes-picker-show" data-click="front:showParamsChoose"><strong class="sizes-picker-text">Choose Your Size</strong>
	        <svg>
	          <use xlink:href="#blackForwardArrow"></use>
	        </svg>
	      </button>
    `;
		}

		tpl += `
			</div>
			<div class="sizes-footer">
				<strong class="red">Hurry, only ${data.count} left in stock!</strong>
        <button class="sizes-details">
          <svg>
            <use xlink:href="#ruler"></use>
          </svg><span>Size Details</span>
        </button>
      </div>
      <div class="sizes-picker-block product-params-hidden">
        <div class="product-params-hidden-block">
          <h5 class="product-params-title">Available Sizes (5)
            <button class="product-params-close" data-click="front:hideParamsChoose">
              <svg>
                <use xlink:href="#product-params-close"></use>
              </svg>
            </button>
          </h5>
          <div class="sizes-picker">
            <div class="sizes-picker-img"><img src="img/sizes.svg" alt="">
            </div>
            ${btnsInnerTpl}
          </div>
        </div>
      </div>
  	`;

		_.clear(cont);
		cont.append(_.markup(tpl));

		if (count == 1) {
			cont.querySelector('.sizes-picker-item').classList.add('active');
			for (let item in data.sizes) {
				_.size = data.sizes[item];
				_.size.title = item;
			}
		} else {
			_.size = {};
		}
	}
	changePhotos(cont,data){
		const _ = this;
		let photosCont = cont.querySelector('.product-photo-list');
		let tpl = ``;
		data.all_images_paths.forEach((photo,index)=>{
			tpl += `<div class="product-photo-item" data-number="${index}"><img src="${photo}" alt=""></div>`
		})

		photosCont.style = `height:${photosCont.clientHeight}px`;
		photosCont.innerHTML = tpl;
		setTimeout(()=>{
			photosCont.removeAttribute('style');
		},50)

		_.photoSliderInit(photosCont);
	}

	showFilter({item}){
		const _ = this;
		let
			filter = _.f('.filter'),
			top = document.querySelector('.filter-top');
		top.classList.add('tr');

		item = top.querySelector('.filter-btn');
		item.classList.toggle('active');
		item.closest('.filter-top').classList.toggle('active');
		item.closest('.products').classList.toggle('active');
		filter.classList.toggle('active');
		filter.nextElementSibling.classList.toggle('active');

		setTimeout(()=>{
			document.querySelector('.filter-top').classList.remove('tr');
		},350)
	}
	showFilterAccord({item}){
		const _ = this;
		if (window.innerWidth < 1024) return;
		let
			body = item.nextElementSibling,
			cont = item.parentElement;
		if (!cont.classList.contains('active')) {
			item.closest('.filter-inner').querySelectorAll('.filter-accord.active').forEach(function (item){
				_.closeFilterAccord(item)
			})

			cont.classList.add('active');
			cont.style = `height:${body.clientHeight + 82}px`
			setTimeout(()=>{
				cont.style = `height:initial;`;
			},350);
		} else {
			_.closeFilterAccord(cont)
			let moreBtn = cont.querySelector('.filter-more');
			if (moreBtn) {
				if (cont.querySelector('.filter-searched-cont')) _.showLessSearch({item:moreBtn})
				else _.showLess({item:moreBtn})
			}
		}
	}
	closeFilterAccord(cont){
		const _ = this;

		cont.style = `height:${cont.clientHeight}px`;
		setTimeout(()=>{
			cont.classList.remove('active');
			cont.removeAttribute('style');
		})
	}
	clearAllFilters({item}){
		const _ = this;

		let searchInputs = document.querySelectorAll('.filter-search-input');
		searchInputs.forEach(function (input){
			input.value = '';
			_.searchFilterItems({item:input});
		})

		_.closeAllFilterAccords();
	}
	closeAllFilterAccords(){
		const _ = this;
		document.querySelectorAll('.filter-accord.active').forEach(function (item){
			_.closeFilterAccord(item)
			let moreBtn = item.querySelector('.filter-more');
			if (moreBtn) {
				if (item.querySelector('.filter-searched-cont')) _.showLessSearch({item:moreBtn})
				else _.showLess({item:moreBtn})
			}
		})
	}
	searchFilterItems({item}){
		const _ = this;
		let list = item.closest('UL');
		let value = item.value;
		list.querySelectorAll('LI').forEach(function (li) {
			let label = li.querySelector('.checkbox-text');
			if (!label) return;
			if (label.textContent.trim().toLowerCase().indexOf(value.trim().toLowerCase()) < 0) li.style = 'display: none;';
			else li.removeAttribute('style');
		})
		let moreButton = list.closest('.filter-accord').querySelector('.filter-more');
		if (moreButton) _.showLessSearch({item:moreButton})
	}
	clearSearchFilterItems({item}) {
		const _ = this;
		let input = item.previousElementSibling;
		input.value = '';
		_.searchFilterItems({item:input})
	}

	changeSize({item},byClick = true){
		const _ = this;
		let cont = item.parentElement;
		if (byClick && item.classList.contains('active')) return;

		_.size = {
			title : item.querySelector('.sizes-picker-title').textContent,
			wide : item.querySelector('.sizes-picker-wide').textContent,
			between : item.querySelector('.sizes-picker-between').textContent,
			length : item.querySelector('.sizes-picker-length').textContent,
			height : item.querySelector('.sizes-picker-height').textContent
		};

		cont.querySelector('.active')?.classList.remove('active');
		item.classList.add('active');

		_.changeSizeAccordText();

		let hiddenCont = item.closest('.product-params-hidden');
		if (hiddenCont) {
			let closeBtn = hiddenCont.querySelector('.product-params-close');
			_.hideParamsChoose({item:closeBtn});
			let chooseBtn = document.querySelector('.sizes-picker-show');
			chooseBtn.innerHTML = `
		    ${item.innerHTML}
		    <svg>
          <use xlink:href="#blackForwardArrow"></use>
        </svg>
		  `;
			chooseBtn.classList.add('active');
		}
	}

	changeSizeAccordText(){
		const _ = this;
		let
			sizeTitleCont = document.querySelector('.size-title'),
			width = document.querySelector('.size-lens'),
			between = document.querySelector('.size-bridge'),
			length = document.querySelector('.size-temple'),
			height = document.querySelector('.size-height');

		sizeTitleCont.firstElementChild.textContent = 'Frame Size: ' + _.size.title;
		sizeTitleCont.lastElementChild.textContent = `${_.size.wide}-${_.size.between}-${_.size.length} / ${_.size.height}`;
		width.lastElementChild.textContent = _.size.wide + 'mm';
		between.lastElementChild.textContent = _.size.between + 'mm';
		length.lastElementChild.textContent = _.size.length + 'mm';
		height.lastElementChild.textContent = _.size.height > 0 ? _.size.height + 'mm' : '';
	}

	getProductInfo(id){

		const frameColorsInput = document.getElementById('frame_colors_data');
		const frameColorsJsonData = frameColorsInput.value;
		const frameColorsData = JSON.parse(frameColorsJsonData);

		const product = {};

		frameColorsData.styles.forEach(function (item) {
			product[item.id] = {
				id: item.id,
				frame: item.color,
				lens: item.lens_color,
				actual_price: item.price,
				price: item.price,
				discount: '0%',
				sizes: item.sizesdeb,
				photos: [item.image_path],
				count: 10,
				vendor: '?',
				color_name: item.color_name,
				color_code: item.color_code,
				upc: item.upc,
                all_images_paths: item.all_images_paths,
			};
		})

		const _ = this;
		let product_old = {
			"1":{
				'id':1,
				'frame':"Transparent Dark Blue / 234ABC",
				'lens':"Blue Mirror",
				'actual_price':200.50,
				'price':259.50,
				'discount':'24%',
				'sizes':{
					'x-small':{
						"wide":38,
						"between":14,
						"length":125,
						"height":40,
					},
					'small':{
						"wide":42,
						"between":16,
						"length":131,
						"height":43,
					},
					'medium':{
						"wide":44,
						"between":17.5,
						"length":135,
						"height":45.4,
					},
					'large':{
						"wide":46,
						"between":19,
						"length":140,
						"height":49,
					},
					'x-large':{
						"wide":48,
						"between":21,
						"length":145,
						"height":51,
					}
				},
				'photos':[
					"0PR_17WS__11N09T__P21__shad__qt.webp",
					"0PR_17WS__11N09T__P21__shad__lt.webp",
					"0PR_17WS__11N09T__P21__shad__bk.webp",
					"0PR_17WS__11N09T__P21__shad__fr.avif",
					"0PR_17WS__11N09T__P21__shad__cfr.avif"
				],
				'count':10,
				'vendor':"Rx5154 Clubmaster"
			},
			"2":{
				'id':2,
				'frame':"Honey tortoise / 234ABC",
				'lens':"Honey tortoise",
				'actual_price':200,
				'price':400,
				'discount':'50%',
				'sizes':{
					'small':{
						"wide":42,
						"between":16,
						"length":131,
						"height":43,
					},
					'medium':{
						"wide":44,
						"between":17.5,
						"length":135,
						"height":45.4,
					},
					'large':{
						"wide":46,
						"between":19,
						"length":140,
						"height":49,
					}
				},
				'photos':[
					"0PR_17WS__VAU2Z1__P21__shad__qt.webp",
					"0PR_17WS__VAU2Z1__P21__shad__lt.webp",
					"0PR_17WS__VAU2Z1__P21__shad__bk.webp",
					"0PR_17WS__VAU2Z1__P21__shad__fr.webp",
					"0PR_17WS__VAU2Z1__P21__shad__cfr.webp"
				],
				'count':15,
				'vendor':"Rx5154 Clubmaster Honey"
			},
			"3":{
				'id':3,
				'frame':"Black / Rx5154",
				'lens':"Black",
				'actual_price':200,
				'price':400,
				'discount':'50%',
				'sizes':{
					'small':{
						"wide":42,
						"between":16,
						"length":131,
						"height":43,
					},
					'medium':{
						"wide":44,
						"between":17.5,
						"length":135,
						"height":45.4,
					},
					'large':{
						"wide":46,
						"between":19,
						"length":140,
						"height":49,
					},
					'x-large':{
						"wide":48,
						"between":21,
						"length":145,
						"height":51,
					}
				},
				'photos':[
					"0PR_17WS__1AB5S0__STD__shad__qt.webp",
					"0PR_17WS__1AB5S0__STD__shad__lt.webp",
					"0PR_17WS__1AB5S0__STD__shad__bk.webp",
					"0PR_17WS__1AB5S0__STD__shad__fr.webp",
					"0PR_17WS__1AB5S0__STD__shad__cfr.webp"
				],
				'count':5,
				'vendor':"Rx5154 Black"
			},
			"4":{
				'id':4,
				'frame':"Powder / Rx5154",
				'lens':"Powder",
				'actual_price':100,
				'price':200,
				'discount':'50%',
				'sizes':{
					'small':{
						"wide":42,
						"between":16,
						"length":131,
						"height":43,
					},
					'medium':{
						"wide":44,
						"between":17.5,
						"length":135,
						"height":45.4,
					},
					'large':{
						"wide":46,
						"between":19,
						"length":140,
						"height":49,
					},
					'x-large':{
						"wide":48,
						"between":21,
						"length":145,
						"height":51,
					}
				},
				'photos':[
					"0PR_17WS__VYJ6X1__STD__shad__qt.webp",
					"0PR_17WS__VYJ6X1__STD__shad__lt.webp",
					"0PR_17WS__VYJ6X1__STD__shad__bk.webp",
					"0PR_17WS__VYJ6X1__STD__shad__fr.webp",
					"0PR_17WS__VYJ6X1__STD__shad__cfr.webp"
				],
				'count':44,
				'vendor':"Rx5154 Powder"
			}
		};

		return product[id];
	}

	changeProductDetails(product) {
		if(product) {
			const productDetailsBlock = document.querySelector('.product-details');

			const lensColorSpan = productDetailsBlock.querySelector('.lens-color span');
			const lensColorStrong = productDetailsBlock.querySelector('.lens-color strong');

			let lensTitle = '';
			let lensContent = '';
			if(product.lens) {
				lensTitle = 'Lens Color:';
				lensContent = product.lens;
			}

			lensColorSpan.textContent = lensTitle;
			lensColorStrong.textContent = lensContent;

			const colorNameStrong = productDetailsBlock.querySelector('.color-name strong');
			colorNameStrong.textContent = product.color_name;

			const colorCodeStrong = productDetailsBlock.querySelector('.color-code strong');
			colorCodeStrong.textContent = product.color_code;

			const productUpcStrong = productDetailsBlock.querySelector('.product-upc strong');
			productUpcStrong.textContent = product.upc;
		}
	}

	selectsInit(){
		const _ = this;
		let selects = document.querySelectorAll('.select[data-items]');
		selects.forEach(function (select){
			_.selectInit(select)
		})
	}
	selectInit(select){
		const _ = this;
		let valueInput = `<input type="hidden" name="${select.getAttribute('data-name')}" class="select-value">`;
		let value = select.getAttribute('data-value');
		let head = `
  	  <button type='button' data-click='front:select' class="select-head">
				<span class="select-head-text">${value ?? ''}</span>
				<svg>
					<use xlink:href='#blackBottomArrow'>
				</svg>
			</button>
  	`;
		let body = `<div class="select-body">`;
		let items = select.getAttribute('data-items');
		if (items) try{
			items = JSON.parse(items);
		} catch(e){
			return;
		}
		items.forEach(function (item){
			if (typeof item == 'object') {
				body += `
					<button type='button' data-value="${item.value}" data-click='front:selectChoose' class="select-option${value == item.value ? ' active' : ''}">
						<span>${item.text}</span>
					</button>
				`;
			} else {
				body += `
					<button type='button' data-value="${item}" data-click='front:selectChoose' class="select-option${value == item ? ' active' : ''}">
						<span>${item}</span>
					</button>
				`;
			}
		})
		body += '</div>';
		let tpl = valueInput + head + body;
		select.innerHTML = tpl;
	}
	select({item}){
		const _ = this;
		let
			select = item.closest('.select'),
			body = select.querySelector('.select-body');

		if (!select.classList.contains('active')) {
			select.classList.add('active');
			let
				childrenCount = body.children.length,
				height = childrenCount * body.firstElementChild.clientHeight,
				scrolling = false;
			if (childrenCount > 5) {
				childrenCount = 5;
				scrolling = true;
			}
			body.style = `height:${height}px;`;
			body.classList.add('active');
			if (scrolling) {
				setTimeout(()=>{
					body.classList.add('scrolling');
				},350)
			}
		} else {
			select.classList.remove('active');
			body.removeAttribute('style');
			body.classList.remove('scrolling');
			body.classList.remove('active');
		}
	}
	selectChoose({item}){
		const _ = this;
		let
			select = item.closest('.select'),
			head = select.querySelector('.select-head'),
			body = select.querySelector('.select-body'),
			valueInput = select.querySelector('.select-value');

		body.querySelector('.active')?.classList.remove('active');
		item.classList.add('active');

		head.querySelector('.select-head-text').textContent = item.textContent;
		valueInput.value = item.getAttribute('data-value') ?? item.textContent;
		_.select({item})
	}

	showBlock({item}){
		const _ = this;
		let
			targetSelector = item.getAttribute('data-target'),
			target = document.querySelector(targetSelector);

		if (item.checked) target.classList.remove('hidden');
		else target.classList.add('hidden');
	}

	async init(){
		const _ = this;
		_.scrollCheck();
		_.rebuildProductPage();
		_.slidersInit();
		_.multiSlidersReInit();

		window.addEventListener('resize',()=>{
			_.rebuildProductPage();
			_.clearAccordsHeight();
			//if (document.querySelector('.photos')) _.closePhotos();
			_.multiSlidersReInit();
		})

		_.selectsInit();

		//lenses flow
		_.lensButtonsInit();

		/*let headCount = 0;
		let headInterval = setInterval(function (e){
			_.headHiddens = document.querySelectorAll('.head-hidden');
			let content = document.querySelector('.head-content');
			if (_.headHiddens.length && content) clearInterval(headInterval);
			window.addEventListener('scroll',(e) => {
				_.headHiddens.forEach(function (elem){
					elem.style = `top:${window.scrollY <= 97 ? 97 - window.scrollY : content.clientHeight}px;`
				})
			})
			if (headCount > 10) clearInterval(headInterval)
			headCount++;
		},1000);*/

		if (window.innerWidth >= 1024) {
			document.querySelectorAll('.lenses-tooltip').forEach(function (el){
				el.addEventListener('mouseenter',function (e){
					_.showTooltip(e)
				})
			})
			document.querySelectorAll('.lenses-tooltip').forEach(function (el){
				el.addEventListener('mouseleave',function (e){
					_.hideTooltip(e)
				})
			})
		} else {
			document.querySelectorAll('.lenses-tooltip').forEach(function (el){
				el.addEventListener('click',function (e){
					_.showTooltip(e)
				})
			})
		}

		await _.configureClient();
		const isAuthenticated = await _.auth0Client?.isAuthenticated();
		window['okta'] = _.auth0Client;
		const query = window.location.search;

        if (!isAuthenticated) {
            localStorage.removeItem('okta_login');
        }

        let isLogin = false;
		const shouldParseResult = query.includes("code=") && query.includes("state=");

        if (shouldParseResult) {
            localStorage.removeItem('okta_login');
        } else {
            isLogin = localStorage.getItem('okta_login');
        }

        if (isLogin) {
            return void 0;
        }

		if (shouldParseResult) {
            let link = localStorage.getItem('redirect_link');

            if (! _.auth0Client) {
                await _.configureClient();
            }

			try {
				const result = await _.auth0Client.handleRedirectCallback();

                if (await _.auth0Client.isAuthenticated()) {
					const user = await _.auth0Client.getUser();
					console.log(user)
					if(!_.checkAuthorize(user)){
						console.log('Not authorized');
					}
					await _.doLoginFromOkta(user);
					//window.history.replaceState({}, document.title, "/");
					localStorage.setItem('okta_login', true);
                    window.location.href = link;
				}
			} catch (err) {
				console.log("Error parsing redirect:", err);
                // window.location.href = link;
                throw err;
			}
			window.history.replaceState({}, document.title, "/");
		}
	}
	async checkAuthorize(user){
		const _ = this;
		const csrfTokenContent = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

		let response = await fetch('/check-author-okta',{
			method:'POST',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-TOKEN": csrfTokenContent
			},
			body: JSON.stringify({
				email: user.email
			})
		});
		return Boolean(response.text());
	}
	async doLoginFromOkta(user){
		const _ = this;
		const csrfTokenContent = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
		return await fetch('/check-login-okta',{
			method:'POST',
			headers: {
				"Content-Type": "application/json",
				"X-CSRF-TOKEN": csrfTokenContent
			},
			body: JSON.stringify({
				email: user.email,
				fname:user.given_name,
				lname:user.family_name,
			})
		});
	}
	// lenses flow
	lensButtonsInit(){
		const _ = this;
		let initedButton = document.querySelector('.lenses-usage-button-cont[data-inited]');
		if(initedButton) _.lensButton({item:initedButton.querySelector('.lenses-usage-button.active')})
	}
	lensButtonClose(cont,item){
		cont.style = `height:${item.getBoundingClientRect().height + 2}px;`;
		setTimeout(()=>{
			cont.classList.remove('transition');
			cont.classList.remove('active');
		},350)
	}
	lensButton({item}){
		const _ = this;
		let
			cont = item.closest('.lenses-usage-button-cont'),
			btns = cont.children;
		if (btns.length < 2) return

		let conts = document.querySelectorAll('.lenses-usage-button-cont');
		conts.forEach(function (unit){
			if (unit != cont && unit.classList.contains('active')) {
				let unitBtn = unit.querySelector('.lenses-usage-button');
				_.lensButtonClose(unit,unitBtn)
			}
		})

		if (cont.classList.contains('active')) {
			_.lensButtonClose(cont,item)
		} else {
			cont.style = `height:${cont.getBoundingClientRect().height}px;`;
			let height = 0;
			for (let i = 0; i < btns.length; i++) {
				height += btns[i].getBoundingClientRect().height;
			}
			cont.classList.add('active');
			setTimeout(()=>{
				cont.classList.add('transition');
				cont.style = `height:${height + 2}px;`;
			})
		}
	}
	lensButtonChoose({item}){
		const _ = this;
		if (item.classList.contains('active')) return;

		let cont = item.closest('.lenses-usage-button-cont');
		if (cont.querySelector('.active')) cont.querySelector('.active').classList.remove('active')
		item.classList.add('active');
	}

	chooseColor({item}){
		const _ = this;
		let activeColors = document.querySelectorAll('.lenses-usage-button-colors .active');
		if (activeColors.length) {
			activeColors.forEach((btn)=>{
				btn.classList.remove('active');
				if (btn.closest('LABEL')) {
					_.turnOnOffColorsLabel(btn,false)
				}
			});
		} else {
			let activeInputs = document.querySelectorAll('.lenses-usage-checkbox-input:checked');
			if (activeInputs.length) {
				activeInputs.forEach(input=>{
					_.turnOnOffColorsLabel(input.nextElementSibling,false);
				})
			}
		}
		item.classList.add('active');

		let
			colorTitle = item.getAttribute('data-color-title').toLowerCase(),
			colorCode = item.getAttribute('data-color');
		if (!colorTitle) return;
		document.querySelector('.lenses-mobile-color img').src = `img/lenses-color-${colorTitle}.svg`;
		document.querySelector('.lenses-product-photo img').src = `img/lenses-color-${colorTitle}.svg`;
		document.querySelector('.lenses-left-fill-line').style = `background:linear-gradient(270deg, ${colorCode} 0%, #fff 100%)`;

		if(item.closest('LABEL')) {
			_.turnOnOffColorsLabel(item,true)
		}
	}
	turnOnOffColorsLabel(item,on = true){
		let label = item.closest('LABEL');
		if (label.hasAttribute('for')) {
			let input = document.querySelector(`#${label.getAttribute('for')}`);
			input.checked = on;
		}
	}
	showTooltip(event){
		const _ = this;
		let item = event.target.closest('.lenses-tooltip');

		if (window.innerWidth < 1024) {
			document.querySelector('.lenses-tooltips')?.classList.add('active');
			return;
		}

		let targetSelector = item.getAttribute('data-target');
		if (!targetSelector) return;

		let tooltip = document.querySelector(targetSelector);
		if (!tooltip || !tooltip.classList.contains('tooltip')) return;

		tooltip.classList.add('tooltip-showed');
		document.body.append(tooltip);

		let
			YOffset = event.clientY + 30,
			XOffset = event.clientX - tooltip.clientWidth * 0.5,
			cls = '';

		if (window.innerHeight < event.clientY + (tooltip.clientHeight * 0.5)) {
			YOffset = event.clientY - 30 - tooltip.clientHeight;
			cls = 'toBottom'
		} else if (window.innerHeight < event.clientY + tooltip.clientHeight + 30) {
			YOffset = event.clientY - 0.5 * tooltip.clientHeight;
			XOffset = event.clientX + 30;
			cls = 'toLeft';
		}

		if (window.innerWidth < XOffset + tooltip.clientWidth) {
			XOffset = event.clientX - 30 - tooltip.clientWidth;
			cls = 'toRight';
		}

		if (cls) tooltip.classList.add(cls)
		tooltip.style = `position:fixed;top:${YOffset}px;left:${XOffset}px`;
	}
	hideTooltip(event){
		const _ = this;
		if (window.innerWidth < 1024) {
			document.querySelector('.lenses-tooltips')?.classList.remove('active');
			return;
		}

		let tooltip = document.querySelector('.tooltip-showed');
		if (!tooltip) return;

		document.querySelector('.lenses-tooltips').append(tooltip);
		tooltip.classList.remove('tooltip-showed');
		tooltip.classList.remove('toLeft');
		tooltip.classList.remove('toRight');
		tooltip.removeAttribute('style')
	}

	chooseDensity({item}){
		const _ = this;
		let cont = item.closest('.lenses-densities'),
			active = cont.querySelector('.active');

		if (active) {
			if (item == active) return;
			active.classList.remove('active');
		}
		item.classList.add('active')
	}

	twoPD({item}){
		const _ = this;
		let target = document.getElementById('twoPD');
		let unit;
		if (item.checked) {
			let selectTpl = `
				<div class="select" id="twoPD" data-value="0.00" data-name="twoPD" data-items="[0.01,0.02,0.03,0.04]"><input type="hidden" name="pupillary-distance" class="select-value">
			    <button type="button" data-click="front:select" class="select-head">
						<span class="select-head-text">0.00</span>
						<svg>
							<use xlink:href="#blackBottomArrow">
						</use></svg>
					</button>
				  <div class="select-body">
						<button type="button" data-value="0.01" data-click="front:selectChoose" class="select-option">
							<span>0.01</span>
						</button>
						<button type="button" data-value="0.02" data-click="front:selectChoose" class="select-option">
							<span>0.02</span>
						</button>
						<button type="button" data-value="0.03" data-click="front:selectChoose" class="select-option">
							<span>0.03</span>
						</button>
						<button type="button" data-value="0.04" data-click="front:selectChoose" class="select-option">
							<span>0.04</span>
						</button>
					</div>
				</div>
			`;
			unit = _.markup(selectTpl);
		} else {
			unit = _.markup(`<input class="lenses-input" type="text" name="pupillary-distance-input" id="twoPD">`);
		}
		target.parentElement.append(unit);
		target.remove();
	}

	showMore({item}){
		const _ = this;
		let cont = item.previousElementSibling;
		cont.style = `max-height:${cont.firstElementChild.clientHeight}px;`;
		item.setAttribute('data-click',`${_.componentName}:showLess`);
		item.textContent = '- Less';
	}
	showLess({item}){
		const _ = this;
		let cont = item.previousElementSibling;
		cont.removeAttribute('style');
		item.setAttribute('data-click',`${_.componentName}:showMore`);
		item.textContent = '+ More';
	}

	showMoreSearch({item}){
		const _ = this;
		let cont = item.previousElementSibling;
		cont.style = `max-height:${cont.firstElementChild.clientHeight}px;`;
		cont.classList.add('not_ordered');
		item.setAttribute('data-click',`${_.componentName}:showLessSearch`);
		item.textContent = '- Less';
	}
	showLessSearch({item}){
		const _ = this;
		let cont = item.previousElementSibling;
		cont.removeAttribute('style');
		cont.classList.remove('not_ordered');
		item.setAttribute('data-click',`${_.componentName}:showMoreSearch`);
		item.textContent = '+ More';
	}

	showSlidePhoto({item}) {
		const _ = this;
		let index = parseInt(item.getAttribute('data-index'));
		item.closest('.block').querySelector('.item-slide').style = `margin-left:-${index * 100}%`;
	}

    async signIn({ item, event }) {
		const _ = this;

		event.preventDefault();

		let provider = item.getAttribute('data-provider');

        if (! _.auth0Client) {
            await _.configureClient();
        }

		try {
			const options = {
				authorizationParams: {
					connection: provider,
				//	redirect_uri: `${window.location.origin}/register_okta`
                //	redirect_uri: window.location.href + (window.location.href.indexOf('?') > -1 ? '&' : '?') + 'okta=true'
                    redirect_uri: `${window.location.origin}/?okta=true`
				}
			}

			localStorage.setItem('redirect_link', window.location.href);

			await _.auth0Client.loginWithRedirect(options);
		} catch (err) {
			console.log("Log in failed", err);
            throw err;
		}
	}

	fetchAuthConfig(){
        return fetch("/auth_config.json")
    }

	async configureClient(){
		const _ = this;
		const response = await _.fetchAuthConfig();
		const config = await response.json();

        if (typeof auth0 === 'undefined') {
            return
        }

		_.auth0Client = await auth0.createAuth0Client({
			domain: config.domain,
			clientId: config.clientId
		});
	};
	async logout(){
		const _ = this;
	}
}
window.Front = new Front();
