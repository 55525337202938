import { usePage } from '@inertiajs/react';

const WelcomeBanner = ({ blok }) => {
    const pageProps = usePage().props;
    const invertColors = blok.invertColors || false;

    if (pageProps.location?.regionCode !== 'NJ') {
        return;
    }

    return (
        <section>
            <div
                className="banner welcome-banner lg:h-[314px] text-[#0C0C0C]"
                style={{
                    background: invertColors ? 'black' : 'linear-gradient(180deg, #F7F4F4 0%, #F4F4F4 47.13%, #E5E5E5 100%)'
                }}
            >
                <div className={"banner-info " + (invertColors ? 'invert-colors' : '')}>
                    <h1 className="text-[24px] md:text-[36px] font-medium">{blok.title}</h1>
                    <p className="text-[16px] pt-4 pb-8">{blok.description}</p>
                    <div className="banner-info-btns">
                        {blok.links.map((link, i) => (
                            <a key={link._uid} href={link.link.url} className={"banner-info-btn " + (i === 1 ? 'inverted' : 'bordered')}>
                                {link.title}
                            </a>
                        ))}
                    </div>
                </div>
                <video src={blok.video.filename} autoPlay={true} muted={true} controls={true} className="max-h-full" loop={true}></video>
            </div>
        </section>
    );
};

export default WelcomeBanner;
